
import { faker } from '@faker-js/faker';
import { isTouchScreen, get_random } from './helpers.js';
import { mockups_pictures } from './variables.js';

document.addEventListener("DOMContentLoaded", function () {

    const teamMembers = [];
    for (let i = 0; i < 5; i++) {
        teamMembers.push({
            name: faker.person.fullName(),
            image: get_random(mockups_pictures.profil),
            picto: faker.image.urlPicsumPhotos()
        });
    }

    if (document.getElementById("team")) {
        teamMembers.forEach(member => {
            const memberHTML = `
                            <div class="team-member">
                                <img src="${member.image}" alt="${member.name}">
                                <h3>${member.name}</h3>
                            </div>
                        `;
            document.getElementById("team").innerHTML += memberHTML;
        });
    }

    const comments = [];
    for (let i = 0; i < 10; i++) {
        comments.push({
            id: i,
            name: faker.person.fullName(),
            comment_title: faker.lorem.sentence(),
            comment: faker.lorem.paragraph()
        });
    }

    function displayComments() {
        const commentList = document.getElementById("comment-list");
        if (commentList) {
            commentList.innerHTML = "";
            comments.forEach(comment => {
                const commentHTML = `
                        <li class="comment">
                            <h3>${comment.comment_title}</h3>
                            <p>${comment.comment}</p>
                        </li>
                    `;
                commentList.innerHTML += commentHTML;
            });
        }
    }

    displayComments();

});