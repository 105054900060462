export const mockups_pictures = {
    profil: [
        'profil_pictures/profil_picture_1.png',
        'profil_pictures/profil_picture_2.png',
        'profil_pictures/profil_picture_3.png',
        'profil_pictures/profil_picture_4.png',
        'profil_pictures/profil_picture_5.png',
        'profil_pictures/profil_picture_6.png',
        'profil_pictures/profil_picture_7.png',
        'profil_pictures/profil_picture_8.png',
        'profil_pictures/profil_picture_9.png',
        'profil_pictures/profil_picture_10.png'
    ]
};